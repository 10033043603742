const initialState = {
  user: null
};

const StoreReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'SET_USER':
          return {
            ...state,
            user: action.payload,
          };
      case 'LOAD_SCREEN':
          return {
              ...state,
              loadScreen: action.payload,
          };

    default:
      return state;
  }
};

export default StoreReducer;
