import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard } from 'src/auth/guard';
import AuthClassicLayout from 'src/layouts/auth/classic';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------


// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));

const NewPasswordModernPage = lazy(() => import('src/pages/auth/new-password'));
const ModernForgotPasswordView = lazy(() => import('src/pages/auth/forgot-password'));
// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'login',
    element: (
      <GuestGuard>
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      </GuestGuard>
    ),
  },
  {
    path: 'register',
    element: (
      <GuestGuard>
        <AuthClassicLayout title="Manage clients more effectively">
          <JwtRegisterPage />
        </AuthClassicLayout>
      </GuestGuard>
    ),
  },
  { path: 'reset-password/:token',
    element: (
      <AuthClassicLayout title="Reset Password">
        <NewPasswordModernPage />
      </AuthClassicLayout>
    )
  },
  { path: 'forgot-password',
    element: (
      <AuthClassicLayout title="Forgot Password">
        <ModernForgotPasswordView />
      </AuthClassicLayout>
    )
  },
];
